<template>
	<div data-component="diagram-thumbnail">
		<icon
			size="sm"
			data-element="close"
			icon="cross"
			@click.native="removeDiagram(true)"
		/>
		<diagram
			:uuid="uuid"
			:isEditMode="false"
			:isThumbnail="true"
		/>
		<actions
			:actions="getActions"
			@actionClick="onActionClick"
		/>
	</div>
</template>

<script>

	import Diagram      from '@/components/diagrams/Diagram';
	import Icon         from '@/components/ui/Icon';
	import Actions      from '@/components/ui/Actions';
	import actionClick  from '@/mixins/actionClick';
	import routeParams  from '@/mixins/routeParams';

	export default {
		components: {
			Diagram,
			Actions,
			Icon
		},
		mixins: [
			actionClick,
			routeParams
		],
		props: {
			uuid: {
				type: String,
				default: undefined
			}
		},
		data: () => ({
			game: undefined
		}),
		computed: {
			getDiagram () {
				return this.$store.getters['quiz/getDiagramByUuid']({
					uuid: this.uuid
				});
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Edit diagram',
							actionId: 'editDiagram'
						}
					]
				};
			}
		},
		watch: {},
		mounted () {},
		methods: {
			editDiagram () {
				this.$store.dispatch('quiz/setDiagramInModalByUuid', {
					uuid: this.uuid
				});
			},
			removeDiagram () {
				this.$store.commit('quiz/removeDiagramByUuid', {
					uuid: this.uuid
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='diagram-thumbnail'] {
		position:relative;
		display:flex;
		justify-content:center;
		flex-direction:column;
		padding:rem(20) rem(20) rem(10);
		border:1px solid $c-brand-grey-lighter-2;
		background-color:$c-white;
		[data-element="close"] {
			position:absolute;
			width:rem(20);
			height:auto;
			top:rem(2);
			right:rem(2);
			cursor:pointer;
			user-select:none;
		}
	}

</style>

<template>
	<div data-component="deal-list-item">
		<component
			:is="getComponent"
			:isInActivity="isInActivity"
			v-bind="getProps"
		/>
	</div>
</template>

<script>

	import Deal   from '@/components/dealLibrary/Deal';
	import Group  from '@/components/dealLibrary/Group';

	export default {
		components: {
			Deal,
			Group
		},
		props: {
			group: {
				type: Object,
				required: true
			},
			isInActivity: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({}),
		computed: {
			getComponent () {
				if (this.group?.deals?.length > 1) {
					return 'Group';
				}
				return 'Deal';
			},
			getProps () {
				if (this.getComponent === 'Deal') {
					return {
						deal: this.group.deals[0]
					};
				}
				return {
					group: this.group
				};
			}
		},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	[data-component='deal-list-item'] {
	}

</style>

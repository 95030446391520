import routeParams         from '@/mixins/routeParams';
import actionClick         from '@/mixins/actionClick';
import invalidFormMessage  from '@/mixins/invalidFormMessage';
import api                 from '@/services/api';

export default {
	mixins: [
		routeParams,
		actionClick,
		invalidFormMessage
	],
	data: () => ({
		activity: {
			id: undefined,
			title: '',
			type: undefined,
			version: 1,
			config: {},
			displayOrder: 9999
		},
		currentConfig: {},
		formLoaded: false,
		isWaiting: undefined
	}),
	props: {
		type: {
			type: String,
			default: undefined
		},
		entryRoute: {
			type: String,
			default: 'teaching'
		},
		isCreate: {
			type: Boolean,
			default: false
		},
		successRoute: {
			type: String,
			default: ''
		},
		cancelRoute: {
			type: String,
			default: ''
		}
	},
	computed: {
		getActions () {
			return {
				primary: [
					(this.isCreate ?
						{
							text: 'Create',
							actionId: 'createActivity',
							btnId: 'createActivity'
						} : false
					),
					(!this.isCreate ?
						{
							text: 'Save',
							actionId: 'updateActivity',
							btnId: 'updateActivity'
						} : false
					),
					{
						text: 'Cancel',
						route: this.cancelRoute
					}
				].filter(Boolean)
			};
		},
		getConfig () {
			return this.currentConfig;
		},
		getBreadcrumbs () {
			return [
				(this.getContext === 'admin' ?
					{
						path: '/admin',
						text: 'Admin'
					} : false
				),
				(this.getContext === 'teaching' ?
					{
						path: '/teaching',
						text: 'Teaching'
					} : false
				),
				{
					path: this.getCoursesRoute,
					text: 'Courses'
				},
				{
					path: this.getCourseRoute,
					text: this.activity.courseTitle ? this.activity.courseTitle : 'Course'
				},
				{
					path: this.getLessonRoute,
					text: this.activity.lessonTitle ? this.activity.lessonTitle : 'Lesson'
				},
				(!this.isCreate ?
					{
						path: this.getActivityPreviewRoute,
						text: this.activity.title
					} : false
				),
				(!this.isCreate ?
					{
						path: `${this.getLessonRoute}/edit`,
						text: 'Edit'
					} : false
				),
				(this.isCreate ?
					{
						path: `${this.getLessonRoute}/create`,
						text: 'New activity'
					} : false
				)
			].filter(Boolean);
		}
	},
	created () {
		this.activity.type = this.type;
		if (this.isCreate) {
			this.formLoaded = true;
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			return;
		}
		this.setActivity();
	},
	methods: {
		async setActivity () {
			const activity = await api[this.entryRoute].getActivityByIdAndLessonId({
				lessonId: this.getLessonId,
				activityId: this.getActivityId
			});
			if (!activity) {
				this.$store.commit('ui/showError');
				return false;
			}
			this.activity = {
				...this.activity,
				...activity
			};
			this.currentConfig = this.activity.config;
			await this.afterSetLoadQuizIntoStore();
			await this.afterSetLoadDealsIntoStore();
			this.formLoaded = true;
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		},
		async createActivity () {
			this.isWaiting = 'createActivity';
			const valid = await this.$refs.form.validate();
			if (!valid) {
				this.isWaiting = undefined;
				this.showInvalidFormMessage();
				return;
			}
			await this.beforeSaveGetDealsFromStore();
			const activity = await api.teaching.createActivityByLessonId({
				lessonId: this.getLessonId,
				activity: this.type === 'quiz' ?
					{
						...this.activity,
						...this.getQuiz
					} : {
						...this.activity,
						config: this.currentConfig
					}
			});
			if (!activity ||
					!await this.onSaveCreateMedia(activity)) {
				this.isWaiting = undefined;
				return;
			}
			this.isWaiting = undefined;
			await this.$router.push(this.successRoute);
			this.$store.commit('ui/showNotification', {
				notification: 'The activity has been updated'
			});
		},
		async updateActivity () {
			this.isWaiting = 'updateActivity';
			const valid = await this.$refs.form.validate();
			if (!valid) {
				this.isWaiting = undefined;
				this.showInvalidFormMessage();
				return;
			}
			await this.beforeSaveGetDealsFromStore();
			const activity = await api[this.entryRoute].updateActivityByIdAndLessonId({
				lessonId: this.getLessonId,
				activityId: this.getActivityId,
				activity: this.type === 'quiz' ?
					{
						...this.activity,
						...this.getQuiz
					} : {
						...this.activity,
						config: this.currentConfig
					}
			});
			if (!activity ||
					!await this.onSaveCreateMedia(activity)) {
				this.isWaiting = undefined;
				return;
			}
			this.isWaiting = undefined;
			await this.$router.push(this.successRoute);
			this.$store.commit('ui/showNotification', {
				notification: 'The activity has been updated'
			});
		},
		async createMedia ({ activityId }) {
			const media = await api[this.entryRoute].createMediaByActivityIdAndLessonId({
				activityId,
				lessonId: this.getLessonId,
				media: this.form
			});
			if (!media) {
				return false;
			}
			return media;
		},
		async deleteMedia () {
			if (!this?.activity?.media?.[0]?.id) {
				return false;
			}
			this.isWaiting = 'deleteMedia';
			const response = await api[this.entryRoute].deleteMediaByIdAndActivityIdAndLessonId({
				activityId: this.getActivityId,
				lessonId: this.getLessonId,
				mediaId: this.activity.media[0].id
			});
			if (!response) {
				this.isWaiting = undefined;
				return false;
			}
			this.isWaiting = undefined;
			this.activity.media = [];
			this.$store.commit('ui/showNotification', {
				notification: 'Media has been deleted'
			});
		},
		afterSetLoadQuizIntoStore () {
			if (this.type !== 'quiz') {
				return;
			}
			this.$store.dispatch('quiz/setQuiz', {
				quiz: this.activity
			});
		},
		afterSetLoadDealsIntoStore () {
			if (this.type !== 'deal') {
				return;
			}
			if (!this.getConfig?.deals?.length) {
				this.$store.commit('dealLibrary/setActivity', {
					deals: []
				});
				return;
			}
			this.$store.commit('dealLibrary/setActivity', {
				deals: this.getConfig?.deals
			});
		},
		async beforeSaveGetDealsFromStore () {
			if (this.type !== 'deal') {
				return;
			}
			const deals = this.$store.getters['dealLibrary/getDealsInActivity'];
			this.getConfig.deals = deals;
		},
		afterSaveSetDealsFromStore () {
			if (this.type !== 'deal') {
				return;
			}
			if (!this.getConfig.deals?.length) {
				this.$store.commit('dealLibrary/addToActivity', {
					deals: []
				});
				return;
			}
			this.$store.commit('dealLibrary/addToActivity', {
				deals: this.getConfig.deals
			});
		},
		async onSaveCreateMedia (activity) {
			if (this.type !== 'video' &&
					this.type !== 'embeddedPresentation') {
				return true;
			}
			if (!this.form) {
				return true;
			}
			const media = await this.createMedia({
				activityId: activity.id
			});
			if (!media) {
				this.$router.push(this.successRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'The file could not be uploaded. Please edit the activity and try again.'
				});
				return false;
			}
			return true;
		}
	}
};

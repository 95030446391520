<template>
	<div data-component="deal">
		<icon
			data-element="preview-icon"
			icon="show"
			colour="blue"
			size="sm"
			@click.native="togglePreview"
		/>
		<component
			:is="getLinkComponent"
			data-element="deal-info"
			v-bind="getLinkProps"
		>
			<experience-level-badge :level="getExperienceLevel" />
			<div data-element="classified">
				<tooltip
					v-if="deal.classified"
					text="Classified deal"
					position="top"
				>
					<icon
						icon="check"
						colour="green"
						size="xs"
					/>
				</tooltip>
			</div>
			<div data-element="deal-recording">
				<tooltip
					v-if="deal.dealRecordingExists"
					text="Deal recording"
					position="top"
				>
					<icon
						icon="play"
						colour="green"
						size="xs"
					/>
				</tooltip>
			</div>
			<p data-element="name">
				<b>{{ deal.importOrder }}</b> {{ deal.name }}
			</p>
			<p data-element="keywords">
				{{ getKeywordsString }}
			</p>
		</component>
		<btn
			v-if="getShowAddDealToActivity"
			@click.native="addDealToActivity"
			text="Add"
			size="sm"
		/>
		<btn
			v-if="getShowRemoveDealFromActivity"
			@click.native="removeDealFromActivity"
			text="Remove"
			colour="red"
			size="sm"
		/>
		<div
			v-if="hasActionsSlot"
			data-element="actions"
		>
			<slot
				name="actions"
				:deal="deal"
			/>
		</div>
		<portal
			to="modal"
			v-if="previewIsVisible"
		>
			<modal-inner
				@close="togglePreview"
				heading="Preview deal"
			>
				<deal-summary
					:deal="deal"
				/>
			</modal-inner>
		</portal>
	</div>
</template>

<script>

	import DealSummary from '@/components/diagrams/DealSummary';
	import Btn from '@/components/ui/Btn';
	import Icon from '@/components/ui/Icon';
	import Tooltip from '@/components/ui/Tooltip';
	import ExperienceLevelBadge from '@/components/courses/ExperienceLevelBadge';
	import ModalInner from '@/components/ui/modal/ModalInner';
	import actionClick from '@/mixins/actionClick';
	import routeParams from '@/mixins/routeParams';

	export default {
		components: {
			DealSummary,
			Icon,
			Tooltip,
			Btn,
			ExperienceLevelBadge,
			ModalInner
		},
		mixins: [actionClick, routeParams],
		props: {
			deal: {
				type: Object,
				required: true
			},
			api: {
				type: String,
				default: 'dealLibrary'
			},
			context: {
				type: String,
				default: 'library'
			},
			isInActivity: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			previewIsVisible: false
		}),
		computed: {
			getGameState () {
				if (!this.deal) {
					return false;
				}
				return this.deal.deal;
			},
			getLinkComponent () {
				if (this.isInActivity) {
					return 'div';
				}
				return 'router-link';
			},
			getLinkProps () {
				if (this.isInActivity) {
					return false;
				}
				return {
					to: this.getRoute
				};
			},
			getRoute () {
				const query = {
					context: this.context
				};

				if (this.context === 'lesson' && this.getLessonId) {
					query.lessonId = this.getLessonId;
				}

				return {
					path: `/teaching/deals/${this.deal.id}`,
					query
				};
			},
			getExperienceLevel () {
				if (!this.deal.experienceLevel) {
					return '?';
				}
				return this.deal.experienceLevel;
			},
			getDealIsInActivity () {
				return this.$store.getters['dealLibrary/getDealIsInActivityById']({
					id: this.deal.id
				});
			},
			getShowRemoveDealFromActivity () {
				return this.isInActivity && this.getDealIsInActivity;
			},
			getShowAddDealToActivity () {
				return this.isInActivity && !this.getDealIsInActivity;
			},
			getKeywordsString () {
				return this.deal?.keywords?.join(', ');
			},
			hasActionsSlot () {
				return !!this.$scopedSlots.actions;
			}
		},
		methods: {
			removeDealFromActivity () {
				this.$store.commit('dealLibrary/removeDealFromActivityById', {
					id: this.deal.id
				});
			},
			addDealToActivity () {
				this.$store.commit('dealLibrary/addDealToActivity', {
					deal: this.deal
				});
			},
			togglePreview () {
				this.previewIsVisible = !this.previewIsVisible;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='deal'] {
		display:flex;
		align-items:center;
		width:100%;
		padding:rem(8) rem(14);
		background-color:$c-brand-grey-lighter-7;
		border-radius:4px;
		font-size:rem(16);
		line-height:1;
		margin-bottom:rem(8);
		[data-element='preview-icon'] {
			flex-shrink:0;
			width:rem(28);
			height:rem(28);
			margin-right:rem(14);
			cursor:pointer;
		}
		[data-element='deal-info'] {
			display:flex;
			align-items:center;
			justify-content:flex-start;
			margin:0;
			[data-element='classified'],
			[data-element='deal-recording']{
				flex-shrink:0;
				width:rem(32);
				display: inline-flex;
				justify-content: center;
				[data-component='icon'] {
					width:rem(28);
					height:rem(28);
				}
			}
			[data-element='classified'] {
				margin-left: rem(10);
			}
			[data-element='deal-recording'] {
				margin-right: rem(10);
			}
			[data-element='name'] {
				overflow:hidden;
				flex-shrink:1;
				font-size:rem(16);
				color:$c-brand-blue;
				line-height:1;
				margin-right:rem(24);
				white-space:nowrap;
				text-overflow:ellipsis;
			}
			[data-element='keywords'] {
				overflow:hidden;
				font-size:rem(12);
				line-height:1;
				flex-shrink:20;
				white-space:nowrap;
				text-overflow:ellipsis;
			}
		}
		[data-component='btn'] {
			flex-shrink:0;
			margin:0 0 0 auto;
		}
		[data-element='actions'] {
			display: flex;
			margin-left: auto;
		}
	}

</style>

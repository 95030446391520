<template>
	<div
		data-component="experience-level-badge"
		:data-level="level"
	>
		{{ level }}
	</div>
</template>

<script>

	export default {
		props: {
			level: {
				type: [Number, String],
				default: undefined
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='experience-level-badge'] {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		width: rem(20);
		height: rem(22);
		margin-right: rem(2);
		font-size: rem(14);
		line-height: 1;
		font-weight: 700;
		text-align: center;
		color: $c-white;
		background-color: $c-brand-blue;
		border-radius: 4px;

		&[data-level='1'],
		&[data-level='2'] {
			background-color: $c-experience-levels-beginner-blue;
		}

		&[data-level='3'],
		&[data-level='4'] {
			background-color: $c-experience-levels-improver-green;
		}

		&[data-level='5'],
		&[data-level='6'] {
			background-color: $c-experience-levels-intermediate-red;
		}

		&[data-level='7'],
		&[data-level='8'] {
			background-color: $c-experience-levels-advanced-purple;
		}

		&[data-level='2'],
		&[data-level='4'],
		&[data-level='6'],
		&[data-level='8'] {
			&:before {
				content: '';
				position: absolute;
				top: 3px;
				right: 3px;
				bottom: 3px;
				left: 3px;
				display: block;
				border: 1px solid $c-white;
			}
		}
	}
</style>

<template>
	<div data-component="upload-field">
		<validation-provider
			tag="div"
			v-slot="{ validate, errors, required }"
			:rules="rules"
			:vid="getVid"
			:name="getName"
			ref="validation"
		>
			<label
				data-element="label"
				v-if="getLabel"
				:for="getId"
				:data-required="required"
				:data-error="errors && errors[0]"
				v-html="getLabel"
			/>
			<ul v-if="formHelp">
				<li
					data-element="form-help"
					v-for="(txt, idx) in formHelp"
					:key="idx"
				>
					{{ txt }}
				</li>
			</ul>
			<input
				:id="getId"
				type="file"
				:data-error="errors && errors[0]"
				@change="onChange"
				:placeholder="getPlaceholder"
				ref="input"
			/>
			<p
				v-if="errors[0]"
				data-element="error"
			>
				{{ errors[0] }}
			</p>
		</validation-provider>
	</div>
</template>

<script>

	import { paramCase }  from 'change-case';
	import { camelCase }  from 'change-case';

	export default {
		props: {
			name: {
				type: String,
				required: true
			},
			id: {
				type: String,
				default: undefined
			},
			formHelp: {
				type: Array,
				default: undefined
			},
			value: {
				type: FormData,
				default: undefined
			},
			rules: {
				type: [
					String,
					Object
				],
				default: ''
			},
			placeholder: {
				type: String,
				default: ''
			},
			showLabel: {
				type: Boolean,
				default: true
			},
			fileFormDataKey: {
				type: String,
				default: 'media'
			}
		},
		data: () => ({
			file: undefined
		}),
		computed: {
			getName () {
				return this.name;
			},
			getLabel () {
				if (!this.showLabel) {
					return false;
				}
				return this.name;
			},
			getId () {
				if (!this.id) {
					return paramCase(this.getName);
				}
				return this.id;
			},
			getVid () {
				if (!this.id) {
					return camelCase(this.getName);
				}
				return this.id;
			},
			getPlaceholder () {
				if (!this.placeholder &&
						!this.placeholder.length) {
					return false;
				}
				return this.placeholder;
			}
		},
		watch: {},
		methods: {
			clearValue () {
				this.$refs.input.value = '';
			},
			async onChange (event) {
				const { valid } = await this.$refs.validation.validate(event);
				if (!valid) {
					return;
				}
				if (!event?.target?.files?.[0]) {
					return false;
				}
				const formData = new FormData();
				formData.append(this.fileFormDataKey, event.target.files[0]);
				this.$emit('input', formData);
			}
		}
	};

</script>

<style lang="scss" scoped>

  [data-component='upload-field'] {
		input {
			display:flex;
			width:100%;
			height:rem(32);
			padding:0;
			outline:none;
			border-radius:0;
			font-size:rem(12);
			line-height:rem(32);
			&:placeholder-shown {
				text-overflow: ellipsis;
			}
			&[data-error] {
				border:1px solid $c-red;
			}
			&:hover,
			&:focus {
				border:1px solid $c-brand-grey-lighter-2;
			}
		}

		label {
			&[data-error] {
				color: $c-red;
			}
		}
	}

</style>

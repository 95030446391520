<template>
	<div data-component="deal">
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="submit"
			v-if="formLoaded"
		>
			<input-field
				name="Title"
				type="text"
				rules="required"
				v-model="activity.title"
			/>
		</validation-observer>
		<deal-list
			:isInActivity="isInActivity"
			api="runThrough"
		/>
		<actions
			:actions="getActions"
			@actionClick="onActionClick"
			:isWaiting="isWaiting"
		/>
		<deal-library
			:isInActivity="true"
		/>
	</div>
</template>

<script>

	import InputField        from '@/components/forms/InputField';
	import Actions           from '@/components/ui/Actions';
	import DealList          from '@/components/activities/edit/activityTypes/deal/DealList';
	import DealLibrary       from '@/components/dealLibrary/DealLibrary';
	import activityDefaults  from '@/mixins/activityDefaults';
	import routeParams       from '@/mixins/routeParams';
	import diagramFormat     from '@/mixins/diagramFormat';

	export default {
		components: {
			InputField,
			DealList,
			DealLibrary,
			Actions
		},
		mixins: [
			activityDefaults,
			diagramFormat,
			routeParams
		],
		props: {
			isInActivity: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			currentConfig: {
				gameState: undefined
			},
			currentGameState: undefined,
			currentIndex: 0
		}),
		computed: {},
		mounted () {
		},
		methods: {}
	};

</script>

<style lang="scss" scoped>
</style>

<template>
	<div data-component="deal-list-item">
		<portal
			to="modal"
			v-if="showModal"
		>
			<modal-inner
				@close="togglePreview"
				heading="Preview deal"
			>
				<deal-summary
					:mode="getSimulatorMode"
					:deal="deal"
					:gameState="getGameState"
				/>
			</modal-inner>
		</portal>
		<div data-element="key-info">
			<p data-element="name">
				Name:
				<span v-if="index">
					{{ index + 1 }}
				</span>
				{{ deal.name }}
			</p>
			<p data-element="dealer">
				Dealer: {{ deal.dealer }}
			</p>
			<p data-element="contract">
				Contract: {{ deal.contract }} by {{ deal.declarer }}
			</p>
			<p data-element="opening-lead">
				Opening lead:
				<opening-lead
					v-model="deal.openingLead"
					:deal="deal"
				/>
			</p>
			<p data-element="import-order">
				Import order: {{ deal.importOrder }}
			</p>
		</div>
		<div data-element="deal-info">
			<p data-element="experience-level">
				Experience level: {{ deal.experienceLevel }}
			</p>
		</div>
		<checkbox-field
			name="Start with an empty auction"
			v-model="getStartWithEmptyAuction"
		/>
		<actions
			:actions="getActions"
			@actionClick="onActionClick"
		/>
	</div>
</template>

<script>

	import OpeningLead from '@/components/diagrams/OpeningLead';
	import DealSummary from '@/components/diagrams/DealSummary';
	import Actions from '@/components/ui/Actions';
	import ModalInner from '@/components/ui/modal/ModalInner';
	import CheckboxField from '@/components/forms/CheckboxField';
	import actionClick from '@/mixins/actionClick';
	import { SIMULATOR_MODES } from '@/consts';

	export default {
		components: {
			OpeningLead,
			DealSummary,
			Actions,
			ModalInner,
			CheckboxField
		},
		mixins: [actionClick],
		props: {
			deal: {
				type: Object,
				required: true
			},
			index: {
				type: Number,
				default: undefined
			}
		},
		data: () => ({
			showModal: false
		}),
		computed: {
			getSimulatorMode () {
				return SIMULATOR_MODES.PREVIEW;
			},
			getGameState () {
				if (!this.deal) {
					return false;
				}
				return this.deal.deal;
			},
			getRoute () {
				return `/teaching/deals/${this.deal.id}`;
			},
			getDealIsInActivity () {
				return this.$store.getters['dealLibrary/getDealIsInActivityById']({
					id: this.deal.id
				});
			},
			getStartWithEmptyAuction: {
				get () {
					return this.$store.getters['dealLibrary/getStartWithEmptyAuctionByDealId']({
						id: this.deal.id
					});
				},
				set (value) {
					this.$store.commit('dealLibrary/setStartWithEmptyAuctionByDealId', {
						id: this.deal.id,
						value
					});
				}
			},
			getActions () {
				return {
					secondary: [
						{
							text: 'Preview deal',
							actionId: 'togglePreview'
						},
						{
							text: 'Remove from list',
							actionId: 'removeDealFromActivity'
						}
					].filter(Boolean)
				};
			}
		},
		methods: {
			removeDealFromActivity () {
				this.$store.commit('dealLibrary/removeDealFromActivityById', {
					id: this.deal.id
				});
			},
			togglePreview () {
				this.showModal = !this.showModal;
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='deal-list-item'] {
		display:flex;
		width:100%;
		padding:rem(12) 0;
		border-bottom:1px solid $c-brand-grey-lighter-2;
		font-size:rem(12);
		[data-element='key-info'] {
			width:20%;
			margin-right:5%;
			[data-element='name'] {
				font-weight:700;
				[data-element='index'] {
					font-weight:400;
					font-size:2px;
				}
			}
		}
		[data-element='deal-info'] {
			width:20%;
			margin-right:5%;
			[data-element='experience-level'] {
				font-weight:700;
			}
		}
		[data-element='taxonomies'] {
			width:30%;
		}
		[data-component='actions'] {
			width:30%;
		}
		&:last-child {
			border-bottom:none;
		}
	}

</style>

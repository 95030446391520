<template>
	<div data-component="upload">
		<upload-field
			v-if="!getSrc"
			:name="getFileTitle"
			:formHelp="getHelpText"
			:rules="getValidationRules"
			:fileFormDataKey="fileFormDataKey"
			v-model="getForm"
		/>
		<upload-preview
			v-if="getSrc"
			:src="getSrc"
			:fileType="fileType"
		/>
		<actions
			v-if="getSrc"
			:actions="actions"
			@actionClick="onActionClick"
			size="sm"
			:isWaiting="isWaiting"
		/>
	</div>
</template>

<script>

	import UploadField    from '@/components/uploads/UploadField';
	import UploadPreview  from '@/components/uploads/UploadPreview';
	import Actions        from '@/components/ui/Actions';
	import actionClick    from '@/mixins/actionClick';

	export default {
		components: {
			UploadField,
			UploadPreview,
			Actions
		},
		mixins: [actionClick],
		props: {
			form: {
				type: FormData,
				default: undefined
			},
			media: {
				type: Array,
				default: undefined
			},
			file: {
				type: Array,
				default: undefined
			},
			fileFormDataKey: {
				type: String,
				default: 'media'
			},
			fileType: {
				type: String,
				default: undefined
			},
			helpText: {
				type: Array,
				default: undefined
			},
			isWaiting: {
				type: String,
				default: undefined
			},
			rules: {
				type: Object,
				default: undefined
			},
			name: {
				type: String,
				default: ''
			}
		},
		data: () => ({
			localForm: undefined,
			actions: {
				primary: [
					{
						text: 'Delete media',
						actionId: 'deleteMedia',
						btnId: 'deleteMedia'
					}
				]
			}
		}),
		computed: {
			getValidationRules () {
				if (this.rules) {
					return this.rules;
				}
				if (!this.fileType) {
					return {};
				}
				if (this.fileType === 'pbn') {
					return {
						pbn: 'pbn'
					};
				}
				if (this.fileType === 'pptx') {
					return {
						ppt: 'pptx',
						size: 2048
					};
				}
				if (this.fileType === 'image') {
					return {
						image: 'jpg|jpeg|png',
						size: 2048
					};
				}
				return {
					video: 'mp4',
					size: 512000
				};
			},
			getHelpText () {
				if (this.helpText) {
					return this.helpText;
				}
				if (this.fileType === 'pbn') {
					return [
						'Only PBN files can be uploaded'
					];
				}
				if (this.fileType === 'pptx') {
					return [
						'Only pptx or ppt files can be uploaded',
						'Should take no more than 20 mins to view',
						'Should be no more than 2MB'
					];
				}
				if (this.fileType === 'mp4') {
					return [
						'Only mp4 files can be uploaded',
						'Should be no more than 500MB',
						'Each video should take no more than 20 mins to view'
					];
				}
				return false;
			},
			getFileTitle () {
				if (!this.fileType) {
					return this.name;
				}
				if (this.fileType === 'pbn') {
					return 'PBN';
				}
				if (this.fileType === 'pptx') {
					return 'Presentation file';
				}
				if (this.fileType === 'image') {
					return 'Image file';
				}
				return 'Video file';
			},
			getForm: {
				get () {
					if (!this.localForm) {
						return this.form;
					}
					return this.localForm;
				},
				set (value) {
					this.localForm = value;
				}
			},
			getSrc () {
				const type = this.fileFormDataKey;
				if (!this?.[type]?.[0]?.url) {
					return false;
				}
				return this[type][0].url;
			}
		},
		watch: {
			localForm (newVal) {
				this.$emit('update:form', this.localForm);
			}
		},
		methods: {
			deleteMedia () {
				this.$emit('deleteMedia');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='upload'] {
		[data-component='upload-field'] {
		}
		[data-component='upload-preview'] {
			margin:10px 0 0;
		}

	}

</style>

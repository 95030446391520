<template>
	<nav
		data-component="drop-menu"
		v-click-outside="closeMenu"
	>
		<div
			data-element="btn"
			@click="toggleMenuIsVisible"
		>
			<span>
				{{ caption }}
			</span>
			<icon
				:icon="getIcon"
				colour="white"
			/>
		</div>
		<ul
			data-element="menu"
			v-if="menuIsVisible"
		>
			<li
				v-for="(option, key) in options"
				:key="key"
				data-element="menu-item"
				@click="doAction(option.action)"
			>
				{{ option.text }}
			</li>
		</ul>
	</nav>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			options: {
				type: Array,
				default: undefined
			},
			caption: {
				type: String,
				default: undefined
			}
		},
		data: () => ({
			menuIsVisible: false
		}),
		computed: {
			getIcon () {
				return this.showDrop ? 'caret-up' : 'caret-down';
			}
		},
		methods: {
			closeMenu () {
				this.menuIsVisible = false;
			},
			doAction (action) {
				this.toggleMenuIsVisible();
				action();
			},
			toggleMenuIsVisible () {
				this.menuIsVisible = !this.menuIsVisible;
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='drop-menu'] {
		position:relative;
		[data-element='btn'] {
			display:flex;
			justify-content:center;
			align-items:center;
			background: $c-brand-blue;
			border-radius:rem(4);
			height:rem(42);
			padding:0 rem(10) 0 rem(16);
			cursor:pointer;
			user-select:none;
			span {
				margin:auto rem(8) auto auto;
				color:$c-white;
				font-size:rem(16);
				font-weight:700;
			}
			[data-component='icon'] {
				width:rem(30);
				height:auto;
				margin:auto 0 auto auto;
			}
		}
		[data-element='menu'] {
			position:absolute;
			z-index:20;
			top:rem(48);
			right:0;
			width:100%;
			margin:0;
			padding:rem(4);
			background-color:$c-white;
			border-radius:4px;
			box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.72);
			[data-element='menu-item'] {
				margin:rem(2);
				padding:rem(4) rem(6);
				font-size:rem(16);
				line-height:1;
				font-weight:700;
				color:$c-brand-blue;
				border-radius:4px;
				cursor:pointer;
				user-select:none;
				white-space:nowrap;
				&:hover {
					background-color:$c-brand-blue-lighter-4;
				}
			}
		}
	}
</style>

<template>
	<div data-component="activity">
		<component
			v-if="getActivityComponent"
			:is="getActivityComponent"
			:type="type"
			:entryRoute="entryRoute"
			:isCreate="isCreate"
			:successRoute="successRoute"
			:cancelRoute="cancelRoute"
		/>
	</div>
</template>

<script>

	import TitlePage             from '@/components/activities/edit/activityTypes/TitlePage';
	import Video                 from '@/components/activities/edit/activityTypes/Video';
	import Deal                  from '@/components/activities/edit/activityTypes/Deal';
	import Quiz                  from '@/components/activities/edit/activityTypes/Quiz';
	import EmbeddedPresentation  from '@/components/activities/edit/activityTypes/EmbeddedPresentation';
	import { pascalCase }        from 'change-case';

	export default {
		components: {
			TitlePage,
			Video,
			Deal,
			Quiz,
			EmbeddedPresentation
		},
		props: {
			type: {
				type: String,
				default: undefined
			},
			entryRoute: {
				type: String,
				default: 'teaching'
			},
			isCreate: {
				type: Boolean,
				default: false
			},
			successRoute: {
				type: String,
				default: ''
			},
			cancelRoute: {
				type: String,
				default: ''
			}
		},
		data: () => ({}),
		computed: {
			getActivityComponent () {
				return pascalCase(this.type);
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='activity'] {}
</style>

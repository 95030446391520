<template>
	<div data-component="title-page">
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="submit"
			v-if="formLoaded"
		>
			<input-field
				name="Title"
				type="text"
				rules="required"
				v-model="activity.title"
			/>
			<wysiwyg-field
				name="Body Text"
				type="text"
				rules="required"
				:hasListControl="false"
				v-model="currentConfig.text"
			/>
			<actions
				:actions="getActions"
				@actionClick="onActionClick"
				:isWaiting="isWaiting"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import InputField        from '@/components/forms/InputField';
	import Actions           from '@/components/ui/Actions';
	import activityDefaults  from '@/mixins/activityDefaults';

	export default {
		components: {
			InputField,
			WysiwygField: () => import('@/components/forms/WysiwygField'),
			Actions
		},
		mixins: [activityDefaults],
		data: () => ({
			currentConfig: {
				text: ''
			}
		}),
		computed: {},
		mounted () {},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	[data-component='title-page'] {}

</style>

<template>
	<div data-component="diagrams">
		<div data-element="header">
			<h1
				v-if="getHeadingString"
				data-element="heading"
			>
				{{ getHeadingString }}
			</h1>
			<div data-element="add-diagram">
				<drop-menu
					:options="getAddDiagramOptions"
					caption="Add a diagram"
				/>
			</div>
		</div>
		<div data-element="diagram-list">
			<draggable
				v-if="getDiagrams"
				v-model="getDiagrams"
				v-bind="getDragOptions"
				data-element="draggable"
			>
				<diagram-thumbnail
					v-for="(diagram, key) in getDiagrams"
					:key="key"
					:uuid="diagram.uuid"
					:context="context"
				/>
			</draggable>
		</div>
	</div>
</template>

<script>

	import DiagramThumbnail  from '@/components/activities/edit/activityTypes/quiz/DiagramThumbnail';
	import DropMenu          from '@/components/ui/DropMenu';
	import Draggable         from 'vuedraggable';
	import { sentenceCase }  from 'change-case';

	export default {
		name: 'Diagrams',
		components: {
			DiagramThumbnail,
			DropMenu,
			Draggable
		},
		props: {
			context: {
				type: String,
				default: 'question'
			}
		},
		computed: {
			getDragOptions () {
				return {
					animation:    200,
					ghostClass:   'ghost',
					disabled:     false,
					handle:       '[data-component="diagram-thumbnail"]'
				};
			},
			getHeadingString () {
				return `${sentenceCase(this.context)} diagrams`;
			},
			getAddDiagramOptions () {
				return this.$store.getters['quiz/getAddDiagramOptionsByContext']({
					context: this.context
				});
			},
			getDiagrams: {
				get () {
					return this.$store.getters['quiz/getDiagramsByContext']({
						context: this.context
					});
				},
				set (newVal) {
					this.$store.commit('quiz/setDiagramsByContext', {
						context: this.context,
						diagrams: newVal
					});
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='diagrams'] {
		border:1px solid $c-brand-grey-lighter-2;
		border-radius:4px;
		background:$c-white;
		margin:rem(10) 0;
		[data-element='header'] {
			display:flex;
			align-items:flex-start;
			flex-wrap:nowrap;
			width:100%;
			padding:rem(4) rem(4) rem(4) rem(14);
			border-bottom:1px solid $c-brand-grey-lighter-2;
			cursor:grab;
			user-select:none;
			[data-element='heading'] {
				flex-grow:0;
				flex-shrink:0;
				overflow:hidden;
				margin:auto auto auto 0;
				text-overflow:ellipsis;
				font-size:rem(16);
				font-weight:700;
			}
			[data-element='add-diagram'] {
				margin:0 0 0 auto;
			}
		}
		[data-element='diagram-list'] {
			padding:rem(14);
			[data-element='draggable'] {
				display:flex;
				flex-wrap:wrap;
				align-items:flex-start;
				[data-component='diagram-thumbnail'] {
					flex-shrink:1;
					width:auto;
					height:auto;
					margin:0 rem(12) rem(12) 0;
					cursor:grab;
					&.ghost {
						opacity:0.2;
					}
				}
			}
		}
	}

</style>

<template>
	<div data-component="diagram-modal">
		<portal
			to="modal"
		>
			<modal-inner
				@close="cancel"
				heading="Edit diagram"
			>
				<validation-observer
					data-element="form"
					tag="form"
					ref="form"
					v-slot="{ valid, errors }"
				>
					<diagram
						:uuid="getDiagram.uuid"
						:isEditMode="isEditMode"
						:isAnswerDiagram="getDiagram.context === 'answer'"
						:isValid="valid"
						:errors="errors"
					/>
				</validation-observer>
				<actions
					:actions="getActions"
					@actionClick="onActionClick"
				/>
			</modal-inner>
		</portal>
	</div>
</template>

<script>

	import Diagram      from '@/components/diagrams/Diagram';
	import Actions      from '@/components/ui/Actions';
	import ModalInner   from '@/components/ui/modal/ModalInner';
	import actionClick  from '@/mixins/actionClick';
	import routeParams  from '@/mixins/routeParams';

	export default {
		components: {
			Diagram,
			Actions,
			ModalInner
		},
		mixins: [
			actionClick,
			routeParams
		],
		props: {},
		data: () => ({
			isEditMode: true
		}),
		computed: {
			getDiagram () {
				return this.$store.getters['quiz/getDiagramInModal'];
			},
			getType () {
				if (!this.getDiagram) {
					return false;
				}
				return this.getDiagram.type;
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save diagram',
							actionId: 'saveDiagram'
						},
						(this.getType !== 'BiddingTable' ?
							{
								text: `${this.isEditMode ? 'Preview' : 'Edit'} diagram`,
								actionId: 'toggleEditMode'
							} : false
						)
					].filter(Boolean),
					secondary: [
						{
							text: 'Cancel',
							actionId: 'cancel'
						}
					]
				};
			},
			getHeading () {
				return 'Edit ' + this.getType;
			}
		},
		watch: {},
		mounted () {
			if (!this.getDiagram) {
				this.$store.commit('ui/showError');
				return false;
			}
			this.$store.commit('ui/setLoadingIsHidden');
		},
		methods: {
			async toggleEditMode () {
				if (!await this.validateDiagram()) {
					return;
				}
				this.isEditMode = !this.isEditMode;
			},
			showError () {
				this.$store.commit('ui/showNotification', {
					notification: 'You must correct the diagram to add it to your quiz.'
				});
			},
			async validateDiagram () {
				const isValid = await this.$refs.form.validate();
				if (!isValid) {
					this.showError();
					return;
				}
				return true;
			},
			async saveDiagram () {
				if (!await this.validateDiagram()) {
					return;
				}
				this.$store.commit('quiz/clearDiagramInModal');
				this.$store.commit('ui/setModalIsHidden');
			},
			async cancel () {
				this.$store.dispatch('quiz/revertChangesToDiagramInModal');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='diagram-modal'] {
		[data-component='actions'] {
			margin:0;
		}
	}

</style>

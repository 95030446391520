<template>
	<div data-component="tooltip">
		<slot />
		<span
			data-element="tooltip-text-wrapper"
			:data-position="position"
		>
			<span data-element="tooltip-text">{{ text }}</span>
		</span>
	</div>
</template>

<script>

	export default {
		props: {
			text: {
				type: [String, undefined],
				required: true
			},
			position: {
				type: String,
				validator (value) {
					return ['top', 'right', 'bottom', 'left'].includes(value);
				},
				default: 'top'
			}
		}
	};

</script>

<style lang="scss" scoped>
[data-component='tooltip'] {
	display: inline-flex;
	position: relative;
	[data-element='tooltip-text-wrapper'] {
		position: absolute;
		z-index: 1;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.3s ease-out;
		color: $c-white;
		text-align: center;
		font-size: rem(14);
		padding: rem(5) rem(10);
		border-radius: 8px;
		min-width: rem(120);
		min-height: rem(28);
		background: $c-brand-grey;
		&:after {
			content: " ";
			position: absolute;
			border-width: rem(5);
			border-style: solid;
		}
		&[data-position='top'] {
			inset-block-end: 110%;
			inset-inline-start: 50%;
			margin-inline-start: -60px;
			&:after {
				inset-block-start: 100%;
				inset-inline-start: calc(50% - 5px);
				border-color: $c-brand-grey transparent transparent  transparent;
			}
		}
		&[data-position='right'] {
			inset-block-end: 0;
			inset-inline-start: 110%;
			&:after {
				inset-block-end: rem(9);
				inset-inline-end: 100%;
				border-color: transparent $c-brand-grey transparent transparent;
			}
		}
		&[data-position='bottom'] {
			inset-block-start: 110%;
			inset-inline-start: 50%;
			margin-inline-start: -60px;
			&:after {
				inset-block-end: 100%;
				inset-inline-start: calc(50% - 5px);
				border-color: transparent transparent $c-brand-grey transparent;
			}
		}
		&[data-position='left'] {
			inset-block-end: 0;
			inset-inline-end: 110%;
			&:after {
				inset-block-end: rem(9);
				inset-inline-start: 100%;
				border-color: transparent transparent transparent $c-brand-grey;
			}
		}
		[data-element='tooltip-text'] {
			font-size: rem(14);
			line-height: 1.2;
			@include clamp(12);
		}
	}
	&:hover [data-element='tooltip-text-wrapper'] {
		visibility: visible;
		opacity: 1;
	}
}
</style>

<template>
	<div
		data-component="upload-preview"
		v-if="getShowComponent"
	>
		<component
			v-if="getPreviewComponent"
			:is="getPreviewComponent"
			:src="getSrc"
		/>
	</div>
</template>

<script>

	import PresentationViewer  from '@/components/uploads/PresentationViewer';
	import VideoViewer         from '@/components/uploads/VideoViewer';
	import ImageViewer         from '@/components/uploads/ImageViewer';

	export default {
		components: {
			PresentationViewer,
			VideoViewer,
			ImageViewer
		},
		props: {
			src: {
				type: String,
				default: undefined
			},
			fileType: {
				type: String,
				default: undefined
			}
		},
		computed: {
			getShowComponent () {
				if (!this.getSrc ||
						!this.getPreviewComponent) {
					return false;
				}
				return true;
			},
			getSrc () {
				if (!this.src) {
					return false;
				}
				return this.src;
			},
			getPreviewComponent () {
				if (this.fileType === 'mp4') {
					return 'VideoViewer';
				}
				if (this.fileType === 'ppt' ||
						this.fileType === 'pptx') {
					return 'PresentationViewer';
				}
				if (this.fileType === 'image') {
					return 'ImageViewer';
				}
				return false;
			}
		},
		methods: {
			deleteMedia () {
				this.$emit('deleteMedia');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='upload-preview'] {
		[data-element='actions'] {
			display:flex;
			[data-component='btn'] {
				width:auto;
			}
		}
	}

</style>

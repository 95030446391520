<template>
	<div data-component="image-viewer">
		<img
			v-if="getSrc"
			:src="getSrc"
		/>
	</div>
</template>

<script>

	export default {
		props: {
			src: {
				type: String,
				default: undefined
			}
		},
		computed: {
			getSrc () {
				if (!this.src) {
					return false;
				}
				return this.src;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='image-viewer'] {
		img {
			display:block;
			max-width:100%;
			height:auto;
			margin:0;
		}
	}

</style>
